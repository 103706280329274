<template>
  <div class="lp-radio-container" :style="rcStyle">
    <div class="lp-radio-disabled-group" v-if="disabled"></div>
    <div class="lp-radio-group" v-for="(item,idx) in list" :key="'lp-radio'+idx" :style="groupStyle">
      <div :style="itemStyle">
        <div class="lp-radio-button-group">
          <div :class="{ 'lp-radio-outer' : item[value_name]===selectedVal, 'lp-radio-outer-none': item[value_name]!==selectedVal }"
               @click="clickRadio(idx)">
            <div v-if="item[value_name]===selectedVal" class="lp-radio-inner"></div>
          </div>
        </div>
        <div class="lp-radio-label sub" :style="labelStyle(item[value_name])" @click="clickRadio(idx)">
          <div style="margin-top:-3px">{{ item[label_name] }}</div>
          <!-- 팁 내용 -->
          <div v-if="item.tip" class="tip">
            <i class="material-icons help-icon" @click="clickTip(item)">help_outline</i>
            <transition name="fade">
              <div class="help-outside" @click="clickOutSide" v-if="item.selectedHelp"></div>
            </transition>
          </div>
          <transition name="fade">
            <div class="tip-desc" v-if="item.selectedHelp">{{ item.tip }}</div>
          </transition>
        </div>

        <!-- 인풋과 세트인 경우 -->
        <div v-if="showInput===true" style="padding:0 10px;display:flex">
          <div class="control">
            <input class="input"
                   :placeholder="item.num"
                   v-model="item.num"
                   @input="res=> $emit('onEdit', {value: item[value_name], text:res.target.value})"
                   type="number"
                   :step="step"
                   :disabled="item[value_name]!==selectedVal"
                   v-if="showInput===true && existNum(item)">
          </div>

          <div v-if="item.unit!==undefined" style="margin:auto 4px;color:#555;font-size:0.9rem">{{ item.unit }}</div>
        </div>
      </div>
      <!-- 설명이 있는 경우 -->
      <div v-if="item.detail" class="lp-radio-detail" :style="detailStyle">{{ item.detail }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      data: {
        type: Array,
        default: function() {
          return []
        }
      },
      value: [Boolean,Number,String],
      type: {
        type: String,
        required: false
      },
      showInput: {
        type: Boolean,
        required: false,
        default: undefined
      },
      groupMargin: {
        type: String,
        required: false
      },
      groupWidth: {
        type: String,
        required: false
      },
      raInputWidth: {
        type: String,
        required: false
      },
      detailStyle: {
        type: Object,
        required: false
      },
      block: {
        type: Boolean,
        required: false,
        default: false
      },
      label_name: {
        type: String,
        default: 'label'
      },
      value_name: {
        type: String,
        default: 'value'
      },
      disabled: {
        type: Boolean,
        required: false,
        default: undefined
      },
      step: {
        type: String,
        required: false,
        default: '1'
      }
    },
    data() {
      return {
        list: [],
        selectedVal: '',
        childInputWidth: '',
        selectedTip: undefined
      }
    },
    created() {
      let data = this.cloneItem(this.data);
      data.forEach(item => {
        item.selectedHelp = false;
      });

      this.list = data;

      if ( this.value !== undefined ) {
        this.selectedVal = this.value;
      }

      if ( this.raInputWidth !== undefined ) {
        this.childInputWidth = this.raInputWidth;
      }
      else {
        this.childInputWidth = '90px';
      }

    },
    computed: {
      groupStyle() {
        let style = {
          marginBottom: '16px'
        };

        if ( this.block ) {
          style.display = this.block;
        } else {
          style.display = 'inline-flex';
        }
        if ( this.groupMargin !== undefined ) {
          style.margin = this.groupMargin;
        }
        if ( this.groupWidth !== undefined ) {
          style.width = this.groupWidth;
        }


        return style;
      },
      rcStyle() {
        let style = {};
        if ( this.block ) {
          style.display = 'block';
        }
        return style;
      },
      itemStyle() {
        let style = {
          display: 'flex',
/*          alignItems: 'center'*/
        };
        return style;
      }
    },
    watch: {
      value(n) {
        if(n !== undefined)
          this.selectedVal = n;
      },
      data() {
        this.list = this.data;
      }
    },
    methods: {
      clickTip(item) {
        this.selectedTip = item;
        this.selectedTip.selectedHelp = true;
      },
      clickOutSide() {
        this.selectedTip.selectedHelp = false;
      },
      existNum (item) {
        if(Object.keys(item).indexOf('num')>-1) return true;
        else return false;
      },
      clickRadio(index) {
        this.selectedVal = this.data[index][this.value_name];
        // value 값만 받아옴
        this.$emit('onSelect', this.selectedVal);
        // 인덱스에 해당하는 데이터 받아옴
        this.$emit('onSelectItem', this.data[index]);
        this.$emit('update:value', this.data[index][this.value_name]);
      },
      labelStyle(value) {
        if ( this.type === 'brandFont' ) {
          let style = {};
          style.fontFamily = value;
          return style;
        }
        if ( this.type === 'small' ) {
          return 'font-size: .83rem; margin-left: 4px;';
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .lp-radio-container
    position relative
    display flex
    align-items center
    flex-wrap wrap

  .lp-radio-disabled-group
    position absolute
    width 100%
    height 100%
    z-index 10

  .lp-radio-group
    // display inline-flex
    align-items center
    flex-wrap wrap
    margin-right 20px

  // 셀렉트 여부 상관없이
  // 레이블 밀리지 않도록 크기 고정
  .lp-radio-button-group
    width 20px
    height 20px
    display flex
    align-items center
    justify-content center

  .lp-radio-outer
    background $primary
    width 18px
    height 18px
    display inline-flex
    justify-content center
    align-items center
    border-radius 18px
    cursor pointer

  .lp-radio-outer-none
    background-color #fff
    border 1px solid #dbdbdb
    width 16px
    height 16px
    display inline-flex
    justify-content center
    align-items center
    border-radius 16px
    cursor pointer

  .lp-radio-inner
    background-color #fff
    width 10px
    height 10px
    border-radius 10px
    cursor pointer

  .lp-radio-label
    display flex
    align-items flex-end
    margin-left 8px
    cursor pointer
    font-size 16px

  .lp-radio-detail
    font-size 0.8rem
    color #989898
    margin-left 28px

  .help-outside
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    z-index 120
    background rgba(0,0,0, 0.3)

  .help-icon
    font-size 16px
    cursor pointer
  .help-icon:hover
    color #ff5722

  .tip-desc
    position absolute
    z-index 125
    top 22px
    background-color white
    border-radius 4px
    max-width 300px
    padding 12px 16px
    font-size 12px
    white-space pre-line

  .tip
    text-align end
    font-size .8rem
    position relative
    margin-left 4px

  .fade-enter-active
  .fade-leave-active
    transition opacity .2s
  .fade-enter
  .fade-leave-to
    opacity 0

  @media(max-width:1024px)
    .lp-radio-label
      font-size 15px
</style>
